import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/packages/chateaudemorey/src/components/Layouts/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Un `}<strong parentName="h1">{`Gîte`}</strong>{` entre `}<strong parentName="h1">{`Nancy et Metz`}</strong></h1>
    <p>{`Ancien pigeonnier rénové. Idéal pour roucouler en amoureux …
RDC : Cuisine – 1er Etage Salle de bain, WC, Salon avec TV -DVD
2ème Etage Chambre (lit 180×200)..avec T.V. Entièrement climatisé.
Son plus: Echelle de meunier, vue sur le parc.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "527px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/eb558c8c9ff7a5a36b5b0e5404908c08/44385/LesChambresG%C3%AEte2.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.39999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsSAAALEgHS3X78AAADJElEQVQozwEZA+b8AL3In87X1a+7krG2hr6+rc3Rq9LWqr29lnN2UY+RWLKyaczMheHasdK0vuXT3PPk797C1dW6zti2yevV4ACjsnPc4tXQ1crBxqe+vJzGy5++xITCyHiFgU+KiE6foF65uHDU2pPIu6LMoafXq7PTrbnDqr66m6/GprsAtr1tyc2d0NK6v8Weq69goaJTo6dXt71mj5NcfXlKqaVctbNqnKRpmZV0k3hvmHRrqId7oYmKpYeWtZGeALe7csbKkdPXob3EfaSvWaitXpabUKCmXpCXZ3h6VoGCVZyZX4N7YGtbTHhmUY54X52DZqmObZh9cZZwdgCvs3jEy5S5wYqTn2CLmlJqe0eMlEqJjU2BhlB4f1N3fVGBd1WLcFqSdVmffl+siGW7lW/SsIupjn6VcG8Ap61bq7RijZdYaHU7aHc1P04nXGovRU8uNkQpOEMrPkwweHBUp4prnIBhl3hZtZFnw5102LOOqY19gWZjAI2aQpKgSJeiVYmUTZulWYyUVpqhYFdjMS8yIztBJkRIK4B1WaqPcJN8YoJtWL2acMmngdOwiqySfIdqYwBrfDB9i0CcqVeOn0mWqFGkrmC6v3meo3BcW0F7ck51ZE6Ogm6okHKUdlamhWHBnnXJqITRrIaahHB3ZVYAXnAqcoQ0e4o9b3g4e4s9mahanqVkgXxrZ2NOgXtqhH5pl452nYRpi3FUo4ZmwKJ/vZ5+2bSPn419ZGhcAGNoKmRsLlFbJVFXJGp1OmRsRUpNOTc3MDo3N1FNTmxlTnxxWoxxWnlnVH9vXK+QbLiWcr+derqfgIpwXABmUjpfWzw3NxdSUydoc0JYY1hdZmVocXNxd4JdXVY/QS5QSUBoWUhjVURjVkaLbEyogl1/ZUmznm60qXkAbFA2V0s0NzMkTEcxhH9gxMKv6+rp7+/xysrOi4uKYWZiVlhXVFZRVFBJT0s/XlJBaFdCdllHp41sqqN7AJqDba6YgrSfi7GbibOejMu5o+DRwufd1fHt6Ovq6ePh4tnY2dTU1c3MzsfGyL69vrq7vKmWjJ6Ac455dhSrm9r/SXx6AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Gite de France Nancy",
            "title": "Gite de France Nancy",
            "src": "/static/eb558c8c9ff7a5a36b5b0e5404908c08/44385/LesChambresG%C3%AEte2.png",
            "srcSet": ["/static/eb558c8c9ff7a5a36b5b0e5404908c08/63868/LesChambresG%C3%AEte2.png 250w", "/static/eb558c8c9ff7a5a36b5b0e5404908c08/0b533/LesChambresG%C3%AEte2.png 500w", "/static/eb558c8c9ff7a5a36b5b0e5404908c08/44385/LesChambresG%C3%AEte2.png 527w"],
            "sizes": "(max-width: 527px) 100vw, 527px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span>{`
`}<span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "527px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/eee672ac24cbf16e021fac7ab6578ca1/44385/LesChambresG%C3%AEte1.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.39999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAAsSAAALEgHS3X78AAADJElEQVQozwEZA+b8AGRgXGhkYG1oY3ZzdHd0c3JtZ3JsZ3JrZXN1cHlNS688OMutmsafh6aGbq+UftSek+rZ0dmei+u5m//nxgBta2dwbGh0cW18c3R9dnd1cWxybGd3cm6Di4uFXl+uPDnMr53KpJCXeFyliXDamI735eLdlITttpb/5sUAdHRzeHNygYGDfXh5gn+BhIKEfHh1gH18j5mckG1xqTo2yaubx6GN0qqXzKeYzouC7cjEz3di46uL9c6wAHV0dHx6fIeKk3Bsa318fo2Pl395eYODg4yKjY1eZJwyLsOhjpZ2Zk49N6B/cMqjmdCrpMmRhdCiidqzmABraWl5eX2CdnFsYF1pWVSJhYeAgIduX16EcHWMYmmOLSSsgGRaNSYJAABpRTK9nY25h2vGkWvIlnLHnn0AYExNm5eeopmao52jnpWaoJmdpaWvj4WKi4GKkHN5nzkurWFNoGlVl11ImEc0q19Mokwqq1cro1Urn1U4AHhaYIhud414fZJ7gI55fYhzdX9paYl0d4yBhoRxdXoWFY5APJNOQoUoIoQMC5EdF5xENZxWQ4YtJHUEAwB/YlKEYEaopqmThYOQfXqOe3mNeHOTe3eVgHmcgHCeblakf2SedVuTaVKVcl2WdGCUdmSVd2eFY1J2VkEAhWJQikserYp3xcHBtq2tpZWWt7Gzs6WgoGphoVZDy6uCxaR8wqN9vaJ/sZt9x7+u8/Tx6efiqJuDi3ZXAJJ6dpV5cIpEGdK/tMO6t5t3cr67wa+ur5pTVZAtIcamfr+cdpmDb52Gc5p7YMa4qvb289vRxohmS3BNMQBNPTCQeHaEVkGcZUWnjoWVb2nDw9PFytecWV+LLB+4nXe0lnWtpqCgmpOPcVWdhmark3qkjHOKcFh3W0IARDIgYEUzi3t8iFU4ilIyjm5ww8rjy9rvmFVfgx0UrpV2qY9yqaejl42HdT0WeEEcbTkRcU4tfH17enZvAG5CK1czHG9LN4RcTII+GpBoYrW3zcfa8aNJWIUTDYtsTZhlSqpqV6ljUqFiRZxhTJZaRodcRldPSWRfV9HMiA09nj6XAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Gite de France Metz",
            "title": "Gite de France Metz",
            "src": "/static/eee672ac24cbf16e021fac7ab6578ca1/44385/LesChambresG%C3%AEte1.png",
            "srcSet": ["/static/eee672ac24cbf16e021fac7ab6578ca1/63868/LesChambresG%C3%AEte1.png 250w", "/static/eee672ac24cbf16e021fac7ab6578ca1/0b533/LesChambresG%C3%AEte1.png 500w", "/static/eee672ac24cbf16e021fac7ab6578ca1/44385/LesChambresG%C3%AEte1.png 527w"],
            "sizes": "(max-width: 527px) 100vw, 527px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Gite disponible à partir de 17 heures.
Minimum 2 nuits`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      